import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaBars, FaTimes, FaChevronDown, FaChevronRight } from "react-icons/fa";
import logo from "../../assets/mmpsrpc.svg";
import svkm from "../../assets/svkm.png";
import ksv from "../../assets/ksv.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRefs = useRef([]);
  const headerRef = useRef(null);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(st - lastScrollTop.current) > 5) {
        setIsScrolled(st > 50);
        lastScrollTop.current = st <= 0 ? 0 : st;
      }
    };

    const handleClickOutside = (event) => {
      if (
        isMenuOpen &&
        headerRef.current &&
        !headerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
      if (
        activeDropdown !== null &&
        !dropdownRefs.current[activeDropdown]?.contains(event.target)
      ) {
        setActiveDropdown(null);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, activeDropdown]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const navItems = [
    {
      name: "About Us",
      items: [
        {
          name: "MMPSRPC",
          items: [
            { name: "About MMPSRPC", link: "/aboutus" },
            { name: "Mission", link: "/about/mission" },
            { name: "Vision", link: "/about/vision" },
            { name: "Goals", link: "/about/goals" },
            { name: "Co-ordinators", link: "/faculties" },
          ],
        },
        { name: "KSV", link: "/ksv" },
        { name: "SVKM", link: "/svkm" },
        { name: "IEEE", link: "/ieee" },
      ],
    },
    {
      name: "Activities",
      items: [
        { name: "Hackathons", link: "/hackathons" },
        { name: "Projects", link: "/projects" },
        { name: "Achievements", link: "/achievements" },
        { name: "Events", link: "/events" },
      ],
    },
    {
      name: "Media",
      items: [
        { name: "Gallery", link: "/gallery" },
        { name: "News", link: "/news" },
      ],
    },
    {
      name: "Apply for Research",
      link: "https://drive.google.com/drive/folders/12-MUMb6cEoOsR2LzZ4IqgiILBsuNlAEq",
      external: true
    },
    { name: "Contact Us", link: "/contactus" },
  ];

  const handleDropdownToggle = (index, e) => {
    e.preventDefault();
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(false);
    navigate(link);
  };

  const DesktopNavItem = ({ item, index, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    if (item.items) {
      return (
        <div
          className="relative group"
          ref={dropdownRef}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <button
            onClick={(e) => handleDropdownToggle(index, e)}
            className={`flex items-center text-base font-medium transition-all duration-300 px-3 py-2 rounded-md ${
              level === 0
                ? location.pathname.startsWith(item.link)
                  ? "bg-white text-teal-800"
                  : "text-white hover:bg-teal-600"
                : "w-full text-left text-gray-800 hover:bg-teal-50 hover:text-teal-800"
            }`}
          >
            {item.name}
            {level === 0 ? (
              <FaChevronDown className={`ml-1 ${isOpen ? "transform rotate-180" : ""}`} />
            ) : (
              <FaChevronRight className="ml-auto" />
            )}
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0, x: level === 0 ? 0 : -10, y: level === 0 ? -10 : 0 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0, x: level === 0 ? 0 : -10, y: level === 0 ? -10 : 0 }}
                transition={{ duration: 0.2 }}
                className={`absolute ${
                  level === 0 ? "left-0 mt-2" : "left-full top-0 ml-1"
                } w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}
              >
                <div className="py-1">
                  {item.items.map((subItem, subIndex) => (
                    <DesktopNavItem
                      key={subItem.name}
                      item={subItem}
                      index={`${index}-${subIndex}`}
                      level={level + 1}
                    />
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    }else {
      return item.external ? (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className={`block px-4 py-2 text-base font-medium transition-all duration-300 rounded-md ${
            level === 0
              ? "text-white hover:bg-teal-600"
              : "text-gray-800 hover:bg-teal-50 hover:text-teal-800"
          }`}
        >
          {item.name}
        </a>
      ) : (
        <Link
          to={item.link}
          className={`block px-4 py-2 text-base font-medium transition-all duration-300 rounded-md ${
            level === 0
              ? location.pathname === item.link
                ? "bg-white text-teal-800"
                : "text-white hover:bg-teal-600"
              : location.pathname === item.link
              ? "bg-teal-100 text-teal-800"
              : "text-gray-800 hover:bg-teal-50 hover:text-teal-800"
          }`}
          onClick={() => handleLinkClick(item.link)}
        >
          {item.name}
        </Link>
      );
    }
  };

  const MobileNavItem = ({ item, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);

    if (item.items) {
      return (
        <>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`flex justify-between items-center w-full py-2 px-4 rounded-md transition duration-300 ${
              isOpen
                ? "bg-teal-600 text-white"
                : "text-gray-200 hover:bg-teal-600 hover:text-white"
            }`}
            style={{ paddingLeft: `${level * 1 + 1}rem` }}
          >
            {item.name}
            <FaChevronDown
              className={`ml-2 transform transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.ul
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="mt-1 space-y-1 bg-teal-700 rounded-md"
              >
                {item.items.map((subItem) => (
                  <li key={subItem.name}>
                    <MobileNavItem item={subItem} level={level + 1} />
                  </li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </>
      );
    } else {
      return item.external ? (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className={`block py-2 px-4 rounded-md transition duration-300 text-gray-200 hover:bg-teal-600 hover:text-white`}
          style={{ paddingLeft: `${level * 1 + 1}rem` }}
        >
          {item.name}
        </a>
      ) : (
        <Link
          to={item.link}
          className={`block py-2 px-4 rounded-md transition duration-300 ${
            location.pathname === item.link
              ? "bg-white text-teal-800"
              : "text-gray-200 hover:bg-teal-600 hover:text-white"
          }`}
          style={{ paddingLeft: `${level * 1 + 1}rem` }}
          onClick={() => handleLinkClick(item.link)}
        >
          {item.name}
        </Link>
      );
    }
  };

  return (
    <header
      ref={headerRef}
      className={`bg-gradient-to-r from-teal-800 via-teal-700 to-teal-600 shadow-lg sticky top-0 z-40 transition-all duration-300 ${
        isScrolled ? "py-2" : "py-3 lg:py-4"
      }`}
    >
      <div
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage:
            "url(https://www.transparenttextures.com/patterns/cubes.png)",
        }}
      ></div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <div className="w-14 h-14 sm:w-14 sm:h-14 lg:w-[73px] lg:h-[73px] bg-white rounded-full flex items-center justify-center mr-2 sm:mr-3">
              <img
                src={logo}
                alt="MMPSRPC Logo"
                className="w-12 h-12 sm:w-12 sm:h-12 lg:w-16 lg:h-16"
              />
            </div>
            <div>
              <h1 className="text-sm sm:text-base lg:text-lg xl:text-xl font-bold text-white hover:text-beige-300 transition duration-300">
                <span className="lg:hidden">MMPSRPC</span>
                <span className="hidden lg:inline">
                  M. M. Patel Students Research Project Cell
                </span>
              </h1>
              <p className="text-sm sm:text-md lg:text-lg text-[#F8E7C1] font-semibold">
                Kadi Sarva Vishwavidyalaya
              </p>
            </div>
          </Link>

          <nav className="hidden lg:flex items-center space-x-2 xl:space-x-4">
            {navItems.map((item, index) => (
              <DesktopNavItem key={item.name} item={item} index={index} />
            ))}
          </nav>

          <div className="hidden lg:flex items-center space-x-2 xl:space-x-3">
            <a
              href="https://www.ksv.ac.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-shrink-0"
            >
              <img
                src={ksv}
                alt="KSV Logo"
                className="w-12 h-12 xl:w-14 xl:h-14 transform hover:scale-105 transition-transform duration-300"
              />
            </a>
            <a
              href="https://www.svkm.org.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-shrink-0"
            >
              <img
                src={svkm}
                alt="SVKM Logo"
                className="w-9 h-12 xl:w-12 xl:h-14 transform hover:scale-105 transition-transform duration-300"
              />
            </a>
          </div>

          <button
            className="lg:hidden text-white hover:text-beige-300 transition duration-300"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>


        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="lg:hidden mt-4"
            >
              <nav>
                <ul className="flex flex-col space-y-2">
                  {navItems.map((item) => (
                    <li key={item.name}>
                      <MobileNavItem item={item} />
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="flex justify-center items-center space-x-4 mt-6">
                <a
                  href="https://www.ksv.ac.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ksv}
                    alt="KSV Logo"
                    className="w-12 h-12 transform hover:scale-105 transition-transform duration-300"
                  />
                </a>
                <a
                  href="https://www.svkm.org.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={svkm}
                    alt="SVKM Logo"
                    className="w-11 h-12 transform hover:scale-105 transition-transform duration-300"
                  />
                </a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;